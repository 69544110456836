import * as React from 'react';
import { createComponent } from '@lit/react';
import { SearchWC } from './search.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type SearchProps = GenerateComponentTypeWithChildren<SearchWC>;

export type SearchPropsWithRef = GeneratePropsWithRef<SearchProps>;

export const Search = createComponent({
  tagName: SearchWC.tagname,
  elementClass: SearchWC,
  react: React,
}) as SearchPropsWithRef;

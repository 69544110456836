/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DomainTypeEnum } from '@pypestream/api-services';
import {
  Button,
  Modal,
  ModalIcon,
  ModalProps,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

import {
  FormProjectStateType,
  Loader,
  ProjectEnvironmentsType,
  ReleaseChannels,
} from '../../../../components';
import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../../../xstate/app.xstate';
import { ProjectDetailsTabs } from '../project-details';
import {
  ProjectSettingsGeneralTab,
  ProjectSettingsEnvironmentsTab,
} from './index';
import { Project } from '../../../../xstate/manager.xstate';
import { OrganizationRoleNames, useAuthRole } from '../../../../hooks';

export const sortEnvironmentsByName = (
  arr: {
    domains: {
      type: DomainTypeEnum | undefined;
      url: string | undefined;
    }[];
    description: string | undefined;
    name: string;
  }[]
) => {
  const namesOrder: {
    [key: string]: number;
  } = {
    [ReleaseChannels.Production]: 3,
    [ReleaseChannels.Testing]: 2,
    [ReleaseChannels.Development]: 1,
  };
  return arr.sort((a, b) => namesOrder[a.name] - namesOrder[b.name]);
};

export const RenderProjectDetailsContent: FC<{
  project?: Project;
  tab?: ProjectDetailsTabs;
  environmentId?: string;
  hideDeleteButton?: boolean;
}> = ({ project, tab, environmentId, hideDeleteButton = false }) => {
  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);
  const modalRef = useRef<ModalProps>(null);

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.open = false;
    }
  };

  const openModal = () => {
    if (modalRef.current) {
      modalRef.current.open = true;
    }
  };

  const navigate = useNavigate();

  const { products, projects, accountId, routes, applets } =
    useManagerCtxSelector((ctx) => ({
      products: ctx.tools?.filter(({ productId }) => {
        return ctx.products?.find(({ id }) => id === productId);
      }),

      projects: ctx.projects,
      applets: ctx.applets,
      accountId: ctx.selectedOrgId,
      routes: ctx.routes,
    }));

  const projectId = project?.projectId;
  const sortedProjectEnvironments = sortEnvironmentsByName(
    (project?.projectReleaseChannelConfig || []).map(
      ({ domains, appletInstances, releaseChannel, description, id }) => ({
        domains:
          domains?.map(({ type, url }) => ({
            type,
            url,
          })) || [],
        description,
        name: releaseChannel?.name || '',
        appletInstances: appletInstances,
        id,
      })
    )
  ) as ProjectEnvironmentsType;

  const navigateToProjects = useCallback(
    () => navigate(routes.projects),
    [navigate, routes.projects]
  );

  const onSubmit = ({
    projectReleaseChannelConfigs,
    ...restData
  }: FormProjectStateType) => {
    sendManagerEvent({
      type: 'manager.updateProject',
      projectId,
      ...restData,
      releaseChannelConfig: projectReleaseChannelConfigs.map((config) => ({
        domains: config.domains || [],
        description: config.description || '',
        name: config.name,
      })),
    });
  };

  useEffect(() => {
    if (
      project?.projectId &&
      projects?.length &&
      !projects?.find((p) => p.projectId === project?.projectId)
    ) {
      navigateToProjects();
    }
  }, [navigateToProjects, project?.projectId, projects]);

  const form = useForm<FormProjectStateType>({
    defaultValues: {
      name: '',
      description: '',
      projectIconId: '',
      projectIcon: '',
      countryId: '',
      timeZoneId: '',
      localeId: '',
      productIds: [],
      applets: [],
      projectReleaseChannelConfigs: [],
    },
  });

  useEffect(() => {
    if (!project) return;

    const projectDetails = {
      name: project.name,
      description: project.description,
      projectIconId: project.pictureFile?.publicId,
      projectIcon: project.pictureFile?.url,
      countryId: project.localizationSettings?.country?.id,
      timeZoneId: project.localizationSettings?.timeZone?.id,
      localeId: project.localizationSettings?.locale?.id,
      productIds: project.projectProductSettings?.map(
        ({ productId }) => productId
      ),
      applets: applets,
      projectReleaseChannelConfigs: sortedProjectEnvironments,
    };

    form.reset({ ...projectDetails });
  }, [form, project, applets]);

  const generalTab = (
    <ProjectSettingsGeneralTab
      form={form}
      projectId={projectId}
      products={products}
      accountId={accountId}
      disabled={!isAdminRole}
    />
  );

  const { reset, handleSubmit } = form;

  const getLayout = (tabName?: ProjectDetailsTabs) => {
    switch (tabName) {
      case ProjectDetailsTabs.general:
        return generalTab;
      case ProjectDetailsTabs.environments:
        return (
          <ProjectSettingsEnvironmentsTab
            form={form}
            accountId={accountId}
            projectId={projectId!}
            projectEnvironments={sortedProjectEnvironments}
            disabled={!isAdminRole}
            environmentId={environmentId}
          />
        );
      default:
        return generalTab;
    }
  };

  return !project ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader relative />
    </div>
  ) : (
    <form>
      {getLayout(tab)}
      <Stack
        justifyContent={hideDeleteButton ? 'end' : 'space-between'}
        gutter="small"
      >
        <Modal ref={modalRef} size="medium" onClose={closeModal}>
          <ModalIcon
            name="error"
            slot="header"
            style={{ display: 'flex', justifyContent: 'center' }}
          />
          <Stack slot="header" alignItems="center" direction="column">
            <TextTitle
              size="small"
              i18nKey="manager/projects:projectDetails.deleteModal.areYouSure"
            >
              Are you sure?
            </TextTitle>
            <TextBody variant="secondary">
              <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.youWillLose">
                You&lsquo;ll permanently lose:
              </TranslationComponent>
              <ul>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.accessToProjectTools">
                    access to this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.dataInsideProjectTools">
                    all data inside this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
                <li>
                  <TranslationComponent i18nKey="manager/projects:projectDetails.deleteModal.rolesAssignedForProjectTools">
                    all roles assigned for this Project&lsquo;s tool instances
                  </TranslationComponent>
                </li>
              </ul>
            </TextBody>
          </Stack>
          <Stack slot="footer" justifyContent="end">
            <Button
              variant="ghost"
              size="large"
              type="button"
              onClick={closeModal}
              i18nKey="manager/projects:projectDetails.deleteModal.cancel"
            >
              Cancel
            </Button>
            <Button
              size="large"
              variant="warning"
              data-cy="delete-project"
              type="button"
              onClick={() => {
                sendManagerEvent({
                  type: 'manager.deleteProject',
                  projectId,
                });

                closeModal();
                navigate(routes.projects);
              }}
              i18nKey="manager/projects:projectDetails.deleteModal.removeProject"
            >
              Delete Project
            </Button>
          </Stack>
        </Modal>
        {isAdminRole && !hideDeleteButton && (
          <Stack>
            <Button
              variant="warning"
              size="large"
              onClick={openModal}
              i18nKey="manager/projects:projectDetails.delete"
            >
              Delete Project
            </Button>
          </Stack>
        )}

        {isAdminRole && (
          <Stack>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                reset();
                navigateToProjects();
              }}
              i18nKey="manager/projects:projectDetails.cancel"
            >
              Cancel
            </Button>
            <Button
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={!Object.keys(form.formState.dirtyFields).length}
              i18nKey="manager/projects:projectDetails.save"
            >
              Save
            </Button>
          </Stack>
        )}
      </Stack>
    </form>
  );
};

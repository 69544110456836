import React, { FC } from 'react';
import {
  Button,
  Icon,
  Stack,
  TextTitle,
  Spacer,
  AppletCard,
  AppletCardImage,
  AppletCardAvatar,
  AppletCardTitle,
  Grid,
  GridCell,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { parseISO } from 'date-fns';

const platforms: {
  id: string;
  icon?: string;
  label: string;
  updatedAt: string;
  updatedByUser?: {
    firstName: string;
    lastName: string;
    picture: string;
  };
  checked?: boolean;
}[] = [
  {
    id: '1',
    icon: 'https://images.pexels.com/photos/247676/pexels-photo-247676.jpeg',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: true,
  },
  {
    id: '2',
    icon: 'https://images.pexels.com/photos/247676/pexels-photo-247676.jpeg',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: false,
  },
  {
    id: '3',
    icon: 'https://images.pexels.com/photos/247676/pexels-photo-247676.jpeg',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: true,
  },
  {
    id: '4',
    icon: '',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: false,
  },
  {
    id: '5',
    icon: '',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: false,
  },
  {
    id: '6',
    icon: '',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: false,
  },
  {
    id: '7',
    icon: '',
    label: 'Flow Analytics UI example',
    updatedAt: '2024-09-20T15:15:48.260Z',
    updatedByUser: {
      firstName: 'Serhii',
      lastName: 'Chupryna',
      picture:
        'https://lh3.googleusercontent.com/a/ACg8ocKWmrcAgXDjEhEDoftdY0sqmTsFWU0LaeqcFyTRPoH2DQ=s512-c',
    },
    checked: false,
  },
];

interface EnvironmentPlatformsProps {
  disabled?: boolean;
}

export const EnvironmentPlatforms: FC<EnvironmentPlatformsProps> = ({
  disabled,
}) => {
  return (
    <>
      <Stack
        gutter="2xsmall"
        alignItems="center"
        justifyContent="space-between"
        nowrap
      >
        <TextTitle
          size="xsmall"
          i18nKey="manager/projects:projectDetails.environmentsTab.platformSection.title"
        >
          <Icon
            name="puzzle"
            size="large"
            color="gold"
            variant="filled"
            slot="prefix"
          />
          Platform Integrations
          <Icon name="info" color="gray" size="small" slot="suffix" />
        </TextTitle>
        <Button variant="ghost" size="large" disabled={disabled}>
          <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.platformSection.addButton">
            Add Integrations
          </TranslationComponent>
          <Icon slot="prefix" name="add" />
        </Button>
      </Stack>
      <Spacer size="xlarge" />
      <Grid gutter="small" rowGutter="small">
        {platforms.map((platform) => (
          <GridCell
            key={platform.id}
            xsmall="6"
            small="6"
            medium="6"
            large="4"
            xlarge="3"
          >
            <AppletCard
              interactive
              href="javascript:void(0)"
              checked={platform.checked}
            >
              <AppletCardImage src={platform.icon} />
              <AppletCardTitle label={platform.label}></AppletCardTitle>
              <AppletCardAvatar
                date={parseISO(platform.updatedAt)}
                slot="footer"
                label={`${platform.updatedByUser?.firstName} ${platform.updatedByUser?.lastName}`}
                src={platform.updatedByUser?.picture || ''}
              />
            </AppletCard>
          </GridCell>
        ))}
      </Grid>
    </>
  );
};

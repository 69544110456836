import { IconButtonProps } from '@rjsf/utils';
import { Button } from '@pypestream/design-system';
import React from 'react';

export const SubmitButton = (props: IconButtonProps) => {
  const { onClick, disabled } = props;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      size="medium"
      width="full"
      type="submit"
      className="u-margin-top-medium"
    >
      Submit
    </Button>
  );
};

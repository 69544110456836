import {
  BlockList,
  Button,
  Icon,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
  TextOverline,
} from '@pypestream/design-system';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DomainTypeEnum } from '@pypestream/api-services';

import { OutputProjectFormType } from '../../../components';
import { ProjectEnvironmentField } from '../homepage-modal';

const defaultDomain = { type: DomainTypeEnum.Domain, url: '' };

export type ProjectEnvironmentsPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
};

export const ProjectEnvironments = ({
  nextStep,
  prevStep,
}: ProjectEnvironmentsPropsType) => {
  const form = useFormContext();

  const { control, handleSubmit } = form;

  const productionField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.0.domains',
    rules: {
      minLength: 1,
    },
  });

  const stageField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.1.domains',
  });

  const developmentField = useFieldArray({
    control,
    name: 'projectReleaseChannelConfigs.2.domains',
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Set Release Channel URLs
      </TextTitle>
      <Spacer size="small" />
      <TextBody variant="secondary" textAlign="center">
        Projects organize work around an online property like a website.
        Everything in the project must be tied to a domain and, optionally, its
        subdomains. You can make changes in project settings.
      </TextBody>
      <Spacer size="xlarge" />
      <TextOverline>PRODUCTION</TextOverline>
      <BlockList gutter="small">
        {productionField.fields.map(({ id }, index) => {
          const isTheFirstEl = index === 0;

          return (
            <Stack
              key={id}
              gutter="2xsmall"
              alignItems="center"
              justifyContent="space-between"
              nowrap
            >
              <ProjectEnvironmentField
                name="projectReleaseChannelConfigs.0.domains"
                index={index}
                remove={productionField.remove}
                isForbiddenForRemove={!index}
                form={form}
                required={isTheFirstEl}
                placeholder={
                  isTheFirstEl ? 'https://www.pypestream.com/product/' : ''
                }
              />
            </Stack>
          );
        })}
      </BlockList>
      <Spacer size="small" />
      <Button
        variant="ghost"
        size="large"
        onClick={() => productionField.append(defaultDomain)}
      >
        Add Production URL
        <Icon slot="prefix" name="add" />
      </Button>
      <Spacer size="xlarge" />
      <TextOverline>Testing</TextOverline>
      <BlockList gutter="small">
        {stageField.fields.map(({ id }, index) => (
          <Stack
            key={id}
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <ProjectEnvironmentField
              name="projectReleaseChannelConfigs.1.domains"
              index={index}
              remove={stageField.remove}
              form={form}
            />
          </Stack>
        ))}
      </BlockList>
      <Spacer size="small" />
      <Button
        variant="ghost"
        size="large"
        onClick={() => stageField.append(defaultDomain)}
      >
        Add Stage URL
        <Icon slot="prefix" name="add" />
      </Button>
      <Spacer size="xlarge" />
      <TextOverline>DEVELOPMENT</TextOverline>
      <BlockList gutter="small">
        {developmentField.fields.map(({ id }, index) => (
          <Stack
            key={id}
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <ProjectEnvironmentField
              name="projectReleaseChannelConfigs.2.domains"
              index={index}
              remove={developmentField.remove}
              form={form}
            />
          </Stack>
        ))}
      </BlockList>
      <Spacer size="small" />
      <Button
        variant="ghost"
        size="large"
        onClick={() => developmentField.append(defaultDomain)}
      >
        Add Development URL
        <Icon slot="prefix" name="add" />
      </Button>
      <Spacer size="xlarge" />
      <Stack justifyContent="space-between">
        <Button variant="ghost" size="large" type="button" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="primary"
          size="large"
          type="submit"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};

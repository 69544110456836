import { AppletInstance, AppletStatusEnum } from '@pypestream/api-services';
import {
  AppletCard,
  AppletCardAvatar,
  AppletCardInfo,
  AppletCardTitle,
  Button,
  Icon,
  Modal,
  ModalIcon,
  ModalProps,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
  Tag,
} from '@pypestream/design-system';
import React, { useRef, useState } from 'react';
import { sendManagerEvent } from '../../../../xstate/app.xstate';
import { AppletModal } from './applet-modal';
import { TranslationComponent } from '@pypestream/translations';
import { parseISO } from 'date-fns';

export const EnvironmentApplets = ({
  environmentId,
  applets,
  accountId,
  projectId,
  disabled,
}: {
  environmentId: string;
  applets: Array<AppletInstance>;
  accountId: string | undefined;
  projectId: string | undefined;
  disabled?: boolean;
}) => {
  const modalRef = useRef<ModalProps>(null);
  const [selectedAppletId, setSelectedAppletId] =
    useState<AppletInstance['id']>();

  const isModalOpened = !!selectedAppletId;
  const selectedApplet = applets.find((a) => a.id === selectedAppletId);
  const closeModal = () => setSelectedAppletId(undefined);
  return (
    <>
      {environmentId && (
        <AppletModal modalRef={modalRef} environmentId={environmentId} />
      )}
      <Spacer size="small" />

      <Stack alignItems="center" justifyContent="space-between">
        <TextTitle
          size="xsmall"
          i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.title"
        >
          <Icon
            name="tag"
            size="large"
            color="purple"
            variant="filled"
            slot="prefix"
          />
          Web Applets
          <Icon name="info" color="gray" size="small" slot="suffix" />
        </TextTitle>
        <Button
          disabled={disabled}
          variant="ghost"
          size="large"
          onClick={(e) => {
            if (modalRef.current) {
              modalRef.current.open = true;
            }
          }}
        >
          <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.addButton">
            Add Applet
          </TranslationComponent>
          <Icon slot="prefix" name="add" />
        </Button>
      </Stack>
      {(applets || []).length > 0 && <Spacer size="xlarge" />}
      <Modal
        open={isModalOpened}
        size="medium"
        stayOnClickOutside
        stayOnEsc
        onClose={closeModal}
      >
        <ModalIcon
          name="error"
          slot="header"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <Stack slot="header" alignItems="center" direction="column">
          <TextTitle
            size="small"
            i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.title"
          >
            Are you sure?
          </TextTitle>
          <TextBody variant="secondary">
            <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.description">
              Update Applet Status Description
            </TranslationComponent>
          </TextBody>
        </Stack>
        <Stack slot="footer" justifyContent="end">
          <Button
            variant="ghost"
            size="large"
            type="button"
            onClick={closeModal}
            i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.cancelButton"
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="warning"
            data-cy="delete-project"
            type="button"
            onClick={() => {
              if (!selectedAppletId || !selectedApplet) {
                closeModal();
                return;
              }
              sendManagerEvent({
                type: 'manager.updateApplet',
                status:
                  selectedApplet?.status === AppletStatusEnum.Active
                    ? AppletStatusEnum.Inactive
                    : AppletStatusEnum.Active,
                updateAppletInstanceId: selectedAppletId,
              });
              closeModal();
            }}
            i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.confirmButton"
          >
            Update Applet
          </Button>
        </Stack>
      </Modal>

      <Stack gutter="small" direction="column" alignItems="stretch">
        {applets?.map(
          ({
            id,
            status,
            triggers,
            actions,
            name,
            updatedByUser,
            updatedAt,
          }) => (
            <AppletCard
              key={id}
              interactive
              checked={status === AppletStatusEnum.Active}
              onToggleClick={() => setSelectedAppletId(id)}
              href={`/organization/${accountId}/projects/${projectId}/environments/${environmentId}/applets/${id}`}
            >
              <AppletCardTitle label={name || 'Untitled'}>
                <Tag minimal slot="suffix">
                  Cloud
                </Tag>
              </AppletCardTitle>
              <AppletCardInfo label="Trigger">
                {triggers.length
                  ? triggers
                      .map((t) => t?.packageVersion?.package?.displayName)
                      .join(', ')
                  : "You haven't set a triggers"}
              </AppletCardInfo>
              <AppletCardInfo label="Actions">
                {actions.length
                  ? actions
                      .map((a) => a?.packageVersion?.package?.displayName)
                      .join(', ')
                  : "You haven't set an actions"}
              </AppletCardInfo>
              <AppletCardAvatar
                date={parseISO(updatedAt)}
                slot="footer"
                label={`${updatedByUser?.firstName} ${updatedByUser?.lastName}`}
                src={updatedByUser?.picture || ''}
              />
            </AppletCard>
          )
        )}
      </Stack>
    </>
  );
};

import {
  Button,
  Modal,
  ModalIcon,
  PageBody,
  Spinner,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { useNavigate, useParams } from 'react-router-dom';

import Builder from '@pypestream/app-loader-builder';
import { IPage } from '../pages/types';
import { WithRouteData } from '../render-routes';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../xstate/app.xstate';
import { useEffect, useState } from 'react';

export const ReleaseChannelPage = (props: IPage) => {
  const params = useParams();
  const {
    applet_id: appletIdQueryParam,
    environment_id: environmentIdQueryParam,
  } = params;

  const { applet, routes, selectedProject } = useManagerCtxSelector((ctx) => ({
    applet: ctx.applets?.find((a) => a.id === appletIdQueryParam),
    routes: ctx.routes,
    selectedProject: ctx.selectedProject,
  }));
  const navigate = useNavigate();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const closeModal = () => setIsModalOpened(false);

  const isAppletDeleted = useManagerStateMatches(
    'orgRelated.ready.applets.deleting'
  );
  useEffect(() => {
    if (isAppletDeleted) {
      navigate(
        `${routes.projects}/${selectedProject}/environments/${environmentIdQueryParam}`
      );
    }
  }, [
    navigate,
    routes,
    selectedProject,
    environmentIdQueryParam,
    isAppletDeleted,
  ]);
  if (!appletIdQueryParam) {
    return <div>appletInstanceId is undefined</div>;
  }
  if (!applet) {
    return <Spinner fullpage />;
  }
  return (
    <PageBody background="none">
      <Modal
        open={isModalOpened}
        size="medium"
        stayOnClickOutside
        stayOnEsc
        onClose={closeModal}
      >
        <ModalIcon
          name="error"
          slot="header"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <Stack slot="header" alignItems="center" direction="column">
          <TextTitle
            size="small"
            i18nKey="manager/projects:projectDetails.deleteAppletModal.areYouSure"
          >
            Are you sure?
          </TextTitle>
          <TextBody variant="secondary">
            <TranslationComponent i18nKey="manager/projects:projectDetails.deleteAppletModal.youWillLose">
              You&lsquo;ll permanently lose:
            </TranslationComponent>
            <ul>
              <li>
                <TranslationComponent i18nKey="manager/projects:projectDetails.deleteAppletModal.accessToApplet">
                  access to this Applet instance
                </TranslationComponent>
              </li>
            </ul>
          </TextBody>
        </Stack>
        <Stack slot="footer" justifyContent="end">
          <Button
            variant="ghost"
            size="large"
            type="button"
            onClick={closeModal}
            i18nKey="manager/projects:projectDetails.deleteAppletModal.cancel"
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="warning"
            data-cy="delete-project"
            type="button"
            onClick={() => {
              closeModal();
              sendManagerEvent({
                type: 'manager.deleteApplet',
                id: applet.id,
              });
            }}
            i18nKey="manager/projects:projectDetails.deleteAppletModal.removeApplet"
          >
            Delete Project
          </Button>
        </Stack>
      </Modal>
      <Builder
        applet={applet}
        includePrerelease={
          process.env.ENVIRONMENT?.toLowerCase() !== 'production'
        }
        onDelete={() => setIsModalOpened(true)}
        onSubmit={(data) => {
          sendManagerEvent({
            type: 'manager.updateApplet',
            ...data,
          });
        }}
      />
    </PageBody>
  );
};

export default WithRouteData(ReleaseChannelPage);

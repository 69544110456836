import {
  Button,
  Icon,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
  TextCaption,
} from '@pypestream/design-system';
import { useController, useFormContext } from 'react-hook-form';

import { Product } from '../../../utils';
import { OutputProjectFormType } from '../../../components';
import { ProjectToolField } from '../homepage-modal';
import { useRef } from 'react';

export type ProjectToolingPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
  products: Product[];
};

export const ProjectTooling = ({
  nextStep,
  prevStep,
  products,
}: ProjectToolingPropsType) => {
  const form = useFormContext();

  const { control, handleSubmit } = form;

  const { fieldState } = useController({
    name: 'productIds',
    control,
    rules: { validate: (value) => !!value?.length },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        Select tooling to integrate
      </TextTitle>
      <Spacer size="small" />
      <TextBody variant="secondary" textAlign="center">
        Projects are a collection of tools organized around a digital property
        like a website. Each tool can have its own users and permissions. Select
        which tools you’d like to use with your online property:
      </TextBody>
      <Spacer size="2xlarge" />
      <Stack justifyContent="center" gutter="large">
        {products.map(({ productId, logo, label }, index) => {
          const isRequired =
            index === 0 && form.getValues('productIds').length === 0;

          return (
            <ProjectToolField
              key={productId}
              productId={productId}
              logo={logo}
              label={label}
              form={form}
              required={isRequired}
            />
          );
        })}
      </Stack>
      {fieldState.invalid && (
        <>
          <Spacer size="xlarge" />
          <TextCaption variant="danger" textAlign="center">
            <Icon
              name="warning"
              size="xsmall"
              weight="regular"
              className="u-margin-right-xsmall"
            />
            Please select one or more tools
          </TextCaption>
        </>
      )}
      <Spacer size="xlarge" />
      <Stack justifyContent="space-between">
        <Button variant="ghost" size="large" type="button" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="primary"
          size="large"
          type="submit"
          onClick={handleSubmit(nextStep)}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};

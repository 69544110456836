import React, { FC } from 'react';
import {
  TextTitle,
  Spacer,
  PageSection,
  BlockList,
  Grid,
  TextSubtitle,
  GridCell,
} from '@pypestream/design-system';
import { CreateOrganizationFields, Users } from './create-organization-fields';
import { UseFormReturn } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnFormType = UseFormReturn<any, unknown, undefined>;

type OrganizationDetailsPropsType = {
  form: ReturnFormType;
  organizationId: string;
  parentId: string;
  users: Users;
  isAdminRole: boolean;
  isEdit?: boolean;
};

const {
  OrganizationNameField,
  OrganizationAuthProviderField,
  OrganizationIconField,
  OrganizationEmailDomainField,
} = new CreateOrganizationFields();

export const OrganizationDetails: FC<OrganizationDetailsPropsType> = ({
  form,
  organizationId,
  parentId,
  users,
  isAdminRole,
  isEdit,
}) => {
  const { watch } = form;

  const authProvider = watch('authProvider');

  return (
    <PageSection>
      <TextTitle size="xsmall" i18nKey="manager/preferences:general.details">
        Details
      </TextTitle>
      <Spacer size="large" />
      <BlockList gutter="large">
        <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle
              size="small"
              variant="secondary"
              i18nKey="manager/organizations:createOrg.name"
            >
              Name*
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            <OrganizationNameField _form={form} readonly={!isAdminRole} />
          </GridCell>
        </Grid>

        <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle
              size="small"
              variant="secondary"
              i18nKey="manager/organizations:createOrg.icon"
            >
              Icon
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            <OrganizationIconField
              form={form}
              readOnly={!isAdminRole}
              organizationId={organizationId || ''}
            />
          </GridCell>
        </Grid>

        {/* <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle
              size="small"
              variant="secondary"
              i18nKey="manager/organizations:createOrg.accountManager"
            >
              Account Manager
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            <OrganizationAccountManagerField
              users={users || []}
              {...form.register('accountManagerId')}
            />
          </GridCell>
        </Grid> */}

        <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle
              size="small"
              variant="secondary"
              i18nKey="manager/organizations:createOrg.authProvider"
            >
              Auth Provider*
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            <OrganizationAuthProviderField
              disabled={isEdit}
              _form={form}
              parentId={parentId}
            />
          </GridCell>
        </Grid>
        {authProvider === 'google-sso' && (
          <Grid alignItems="center">
            <GridCell xsmall="12" medium="3">
              <TextSubtitle
                size="small"
                variant="secondary"
                i18nKey="manager/organizations:createOrg.emailDomain"
              >
                Email Domain
              </TextSubtitle>
            </GridCell>
            <GridCell xsmall="12" medium="9">
              <OrganizationEmailDomainField _form={form} disabled={isEdit} />
            </GridCell>
          </Grid>
        )}
      </BlockList>
    </PageSection>
  );
};

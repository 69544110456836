import React, { FC } from 'react';
import {
  PageSection,
  Grid,
  GridCell,
  Stack,
  Icon,
  TextTitle,
  Spacer,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { useFieldArray } from 'react-hook-form';
import { AppletInstance } from '@pypestream/api-services';

import { ReturnFormType, ProjectEnvironmentType } from '../../../../components';
import { EnvironmentSummary } from './environment-summary';
import { EnvironmentPlatforms } from './environment-platforms';
import { EnvironmentCertificates } from './environment-certificates';
import { EnvironmentApplets } from './environment-applets';

import { useGlobalAppCtxSelector } from '../../../../xstate/app.xstate';

interface EnvironmentPanelProps {
  form: ReturnFormType;
  environment: ProjectEnvironmentType;
  projectId: string;
  environmentIndex: number;
  disabled?: boolean;
  accountId?: string;
}

export const EnvironmentPanel: FC<EnvironmentPanelProps> = ({
  form,
  environment,
  projectId,
  environmentIndex,
  disabled,
  accountId,
}) => {
  const { control, getValues } = form;

  // Dynamically create the field array for the found release channel
  const environmentField = useFieldArray({
    control,
    name: `projectReleaseChannelConfigs.${environmentIndex}.domains`,
    rules: {
      minLength: 1,
    },
  });

  const enablePlatformIntegrations = useGlobalAppCtxSelector(
    (ctx) => !!(ctx.featureFlags && ctx.featureFlags['platform-integrations'])
  );

  const enableAppletPrototype = useGlobalAppCtxSelector(
    (ctx) => !!(ctx.featureFlags && ctx.featureFlags['applet-prototype'])
  );

  const [applets] = getValues(['applets']);

  const [releaseChannelId] = getValues([
    `projectReleaseChannelConfigs.${environmentIndex}.id`,
  ]);

  const envVariant = (() => {
    switch (environment?.name) {
      case 'Testing':
        return 'staging';
      case 'Production':
        return 'prod';
      case 'Development':
        return 'dev';
      default:
        return 'dev';
    }
  })();

  return (
    <div className={`c-environments c-environments--${envVariant}`}>
      <Stack alignItems="center" gutter="small">
        <div className="c-environments__icon">
          <Icon name="cloud" size="small" color="teal" />
        </div>
        <TextTitle size="medium">
          <TranslationComponent
            i18nKey={`manager/projects:projectDetails.environmentsTab.environmentName`}
            values={{
              environmentName: environment?.name,
            }}
          >
            {environment?.name}
          </TranslationComponent>
        </TextTitle>
      </Stack>
      <Spacer size="xlarge" />
      <PageSection className="c-environments__section">
        <EnvironmentSummary
          form={form}
          environment={environment?.name || ''}
          environmentField={environmentField}
          environmentIndex={environmentIndex}
          projectId={projectId}
        />
      </PageSection>
      {enablePlatformIntegrations && (
        <PageSection className="c-environments__section">
          <EnvironmentPlatforms disabled={disabled} />
        </PageSection>
      )}
      {enableAppletPrototype && (
        <Grid gutter="large">
          <GridCell xsmall="12" medium="9">
            <PageSection className="c-environments__section">
              <EnvironmentApplets
                applets={applets.filter(
                  (applet: AppletInstance) =>
                    applet.projectReleaseChannelConfigId === releaseChannelId
                )}
                disabled={disabled}
                accountId={accountId}
                projectId={projectId}
                environmentId={releaseChannelId}
              />
            </PageSection>
          </GridCell>
          <GridCell xsmall="12" medium="3">
            <PageSection size="small">
              <EnvironmentCertificates />
            </PageSection>
          </GridCell>
        </Grid>
      )}
    </div>
  );
};

/* eslint-disable no-plusplus */
import { html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { customElement, BaseElement } from '../base-element';

import '../stack/stack.wc';
import '../list/src/inline-list.wc';
import '../buttons/icon-button/icon-button.wc';
import '../text/text-caption.wc';
import '../icon/icon.wc';
import '../input/input.wc';
import '../select';

@customElement('ps-pagination')
export class PaginationWC extends BaseElement {
  @property({ type: Number }) total = 50;

  @property({ type: Number }) pageSize = 10;

  @property({ type: Number }) currentPage = 1;

  @property({ type: Array }) pageSizeOptions = [10, 20, 50, 100];

  get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  handlePageChange(_page: number) {
    let page = _page;
    if (page < 1) page = 1;
    if (page > this.totalPages) page = this.totalPages;
    this.currentPage = page;
    this.emit('change', { detail: { page } });
  }

  handlePageSizeChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    this.pageSize = parseInt(select.value, 10);
    this.currentPage = 1;
    this.emit('show-size-change', {
      detail: { pageSize: this.pageSize },
    });
  }

  renderPageButtons() {
    const minButtonsAmount = 3;
    const { totalPages } = this;
    const { currentPage } = this;
    const pages = [];

    const dots = html`<ps-icon name="meatballs" size="medium"></ps-icon>`;

    for (let i = 1; i <= Math.min(minButtonsAmount, totalPages); i++) {
      pages.push(this.renderPageButton(i));
    }

    if (currentPage !== minButtonsAmount + 1 && currentPage !== totalPages) {
      pages.push(html`${dots}`);
    }

    if (totalPages > 8) {
      if (
        currentPage > minButtonsAmount &&
        currentPage <= totalPages - minButtonsAmount
      ) {
        pages.push(this.renderPageButton(currentPage));
      }

      if (
        currentPage > minButtonsAmount &&
        currentPage <= totalPages - minButtonsAmount - 1
      ) {
        pages.push(html`${dots}`);
      }

      for (let i = totalPages - 2; i <= totalPages; i++) {
        pages.push(this.renderPageButton(i));
      }
    } else {
      for (let i = 6; i <= totalPages; i++) {
        pages.push(this.renderPageButton(i));
      }
    }

    return pages;
  }

  renderPageButton(page: number) {
    return html`
      <ps-button
        variant=${this.currentPage === page ? 'primary' : 'secondary'}
        @click="${() => this.handlePageChange(page)}"
        >${page}</ps-button
      >
    `;
  }

  render() {
    return html`
      <ps-stack justifyContent="end" alignItems="end" gutter="large">
        <div style="width: 110px;">
          <ps-text-caption>Go to page:</ps-text-caption>
          <ps-input
            variant="outlined"
            size="small"
            type="number"
            value="${this.currentPage}"
            min="1"
            max="${this.totalPages}"
            @change="${(e: Event) => {
              e.stopPropagation();
              this.handlePageChange(
                Number((e.target as HTMLInputElement).value)
              );
            }}"
            @blur="${(e: Event) => {
              const target = e.target as HTMLInputElement;
              const value = Number(target.value);
              if (target && value < 1) {
                target.value = '1';
              }
            }}"
          ></ps-input>
        </div>

        <div style="width: 110px;">
          <ps-text-caption>Items per page: </ps-text-caption>
          <ps-select
            size="small"
            @change="${(e: Event) => {
              e.stopPropagation();
              this.handlePageSizeChange(e);
            }}"
            value="${this.pageSize}"
            variant="outlined"
          >
            ${this.pageSizeOptions.map(
              (size) =>
                html`<ps-select-option value="${size}">
                  ${size}
                </ps-select-option>`
            )}
          </ps-select>
        </div>

        <ps-inline-list gutter="xsmall">
          <ps-icon-button
            variant="secondary"
            name="chevron-left"
            ?disabled="${this.currentPage === 1}"
            @click="${() => this.handlePageChange(this.currentPage - 1)}"
          >
            >
          </ps-icon-button>

          ${this.renderPageButtons()}

          <ps-icon-button
            variant="secondary"
            name="chevron-right"
            ?disabled="${this.currentPage === this.totalPages}"
            @click="${() => this.handlePageChange(this.currentPage + 1)}"
          >
          </ps-icon-button>
        </ps-inline-list>
      </ps-stack>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-pagination': PaginationWC;
  }
}

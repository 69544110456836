import React, { FC, useRef } from 'react';
import {
  Grid,
  GridCell,
  Text,
  ModalProps,
  IconButton,
  BlockList,
  InlineList,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { UseFieldArrayReturn } from 'react-hook-form';
import { DomainField } from './domain-field';
import { ChannelURLsModal } from './channel-urls-modal';
import { ReturnFormType } from '../../../../components';

interface EnvironmentSummaryProps {
  form: ReturnFormType;
  environment: string;
  environmentField: UseFieldArrayReturn<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    `projectReleaseChannelConfigs.${number}.domains`,
    'id'
  >;
  environmentIndex: number;
  projectId: string | undefined;
}

export const EnvironmentSummary: FC<EnvironmentSummaryProps> = ({
  form,
  environment,
  environmentField,
  environmentIndex,
  projectId,
}) => {
  const modalRef = useRef<ModalProps>(null);

  return (
    <>
      <ChannelURLsModal
        form={form}
        modalRef={modalRef}
        environment={environment}
        environmentField={environmentField}
        environmentIndex={environmentIndex}
        projectId={projectId}
      />
      <Grid gutter="xlarge" rowGutter="small">
        <GridCell xsmall="4">
          <BlockList gutter="small">
            <InlineList>
              <Text size="3xsmall" fontWeight="medium">
                <TranslationComponent
                  i18nKey="manager/projects:projectDetails.environmentsTab.summarySection.developmentUrls"
                  values={{
                    environmentName: environment,
                  }}
                >
                  {environment}
                </TranslationComponent>
              </Text>
              <IconButton
                name="pencil"
                size="small"
                onClick={(e) => {
                  if (modalRef.current) {
                    modalRef.current.open = true;
                  }
                }}
              />
            </InlineList>
            {environmentField.fields.map((field, index) => (
              <DomainField key={index} field={field} />
            ))}
          </BlockList>
        </GridCell>
        <GridCell xsmall="4">
          <BlockList gutter="small">
            <Text
              size="3xsmall"
              i18nKey="manager/projects:projectDetails.environmentsTab.summarySection.appInterfaces"
              fontWeight="medium"
            >
              App Interfaces
            </Text>
            {/* <Stack justifyContent="start" alignItems="center">
            <Button disabled></Button>
            <Text size="2xsmall">{'Awesome Pype (ID564689)'}</Text>
          </Stack> */}
          </BlockList>
        </GridCell>
        <GridCell xsmall="4">
          <BlockList gutter="small">
            <Text
              size="3xsmall"
              i18nKey="manager/projects:projectDetails.environmentsTab.summarySection.variables"
              fontWeight="medium"
            >
              Variables
            </Text>
            {/* <Text size="2xsmall">submit_payment_1</Text>

          <Button variant="secondary" size="small">
            +3 More
            <Icon slot="suffix" name="open" size="xsmall"></Icon>
          </Button> */}
          </BlockList>
        </GridCell>
      </Grid>
    </>
  );
};

import React, { useEffect, useRef } from 'react';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableWC,
  Icon,
  Image,
  PageBody,
  PageSection,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SQUARE_ICON_BASE64, Loader } from '../../../components';
import { html } from 'lit/static-html.js';
import { formatDistance, parseISO } from 'date-fns';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';

export const Organizations: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.childOrgs.loaded');
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.childOrgs.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { childOrgs, defaultLanguage, organizationId } = useManagerCtxSelector(
    (ctx) => ({
      childOrgs: ctx.childOrgs,
      defaultLanguage: ctx.userInfo?.defaultLanguage,
      organizationId: ctx.selectedOrgId,
    })
  );

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.childOrgs.loadChildOrgs');
    }
  }, [isReadyToLoad]);

  return (
    <>
      <PageBody background="none" width="large">
        <>
          {!loaded ? (
            <PageSection>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader relative />
              </div>
            </PageSection>
          ) : (
            <>
              <Stack alignItems="center" justifyContent="space-between">
                <Stack
                  gutter="small"
                  justifyContent="start"
                  alignItems="center"
                  direction="row"
                  nowrap
                >
                  <Image
                    width="56px"
                    height="56px"
                    alt={childOrgs.currentOrg?.name || 'Organization Logo'}
                    src={
                      childOrgs.currentOrg?.picture ||
                      childOrgs.currentOrg?.pictureFile?.url
                    }
                    fallback={DEFAULT_SQUARE_ICON_BASE64}
                  />
                  <Stack gutter="none" display="inline-flex" direction="column">
                    <TextTitle size="small">
                      {childOrgs.currentOrg?.name}
                    </TextTitle>
                    <Text
                      size="2xsmall"
                      font-weight="normal"
                      variant="secondary"
                    >
                      {t('manager/organizations:childOrgsList.created', {
                        date: formatDistance(
                          parseISO(childOrgs.currentOrg?.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                            locale:
                              dateFnsLocales[
                                defaultLanguage || fallbackLanguage
                              ],
                          }
                        ),
                      })}
                    </Text>
                  </Stack>
                </Stack>
                {childOrgs.currentOrg && (
                  <ButtonGroup>
                    <Button
                      size="large"
                      onClick={() =>
                        navigate(
                          `/organization/${childOrgs.currentOrg?.id}/edit`
                        )
                      }
                    >
                      <Icon slot="prefix" name="edit" size="xsmall" />
                      <TranslationComponent i18nKey="manager/organizations:childOrgsList.editOrg">
                        Edit Org
                      </TranslationComponent>
                    </Button>
                  </ButtonGroup>
                )}
              </Stack>
              <Spacer size="2xlarge" />
              <PageSection>
                <Stack alignItems="center" justifyContent="space-between">
                  <TextTitle size="xsmall">
                    {t('manager/organizations:childOrgsList.childOrgs', {
                      count: childOrgs.count,
                      defaultValue: 'Child Organizations ({{count}})',
                    })}
                  </TextTitle>
                  {isAdminRole && (
                    <ButtonGroup>
                      <Button
                        size="large"
                        onClick={() =>
                          navigate(
                            `/organization/${organizationId}/child-orgs/create`
                          )
                        }
                      >
                        <Icon slot="prefix" name="plus" size="xsmall" />
                        <TranslationComponent i18nKey="manager/organizations:childOrgsList.createOrg">
                          Create Child Org
                        </TranslationComponent>
                      </Button>
                    </ButtonGroup>
                  )}
                </Stack>
                <Spacer size="large"></Spacer>
                <DataTable
                  ref={tableRef}
                  columns={[
                    {
                      accessorKey: 'name',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.name', {
                          defaultValue: 'Name',
                        }) || '',
                      cell: ({ row, renderValue }) => html`
                        <ps-stack
                          gutter="small"
                          justifyContent="start"
                          alignItems="center"
                          direction="row"
                          nowrap
                        >
                          <ps-image
                            style="flex-shrink: 0"
                            width="32px"
                            height="32px"
                            src="${row.original.picture ||
                            row.original.pictureFile?.url ||
                            ''}"
                            fallback=${DEFAULT_SQUARE_ICON_BASE64}
                            alt=${childOrgs.currentOrg?.name ||
                            'Organization Logo'}
                          ></ps-image>

                          <ps-text-subtitle
                            size="small"
                            truncate
                            style="min-width: 0px"
                            >${row.original.name}</ps-text-subtitle
                          >
                        </ps-stack>
                      `,
                    },
                    {
                      accessorKey: 'users',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.users', {
                          defaultValue: 'Users',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.users?.count || '0'}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'teams',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.teams', {
                          defaultValue: 'Teams',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.teams?.rows
                            ?.map?.((team) => team.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'projects',
                      header: () =>
                        t(
                          'manager/organizations:childOrgsList.table.projects',
                          {
                            defaultValue: 'Projects',
                          }
                        ) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.projects?.rows
                            ?.map?.((project) => project.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'organizations',
                      header: () =>
                        t(
                          'manager/organizations:childOrgsList.table.childOrgs',
                          {
                            defaultValue: 'Child Orgs',
                          }
                        ) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.allChildAccounts
                            ?.map?.((org) => org.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'updated',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.updated', {
                          defaultValue: 'Last updated',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<div style="min-width: 70px">
                          <ps-text-body variant="secondary" size="small">
                            ${formatDistance(
                              parseISO(row.original.updatedAt),
                              new Date(),
                              {
                                addSuffix: true,
                                locale:
                                  dateFnsLocales[
                                    defaultLanguage || fallbackLanguage
                                  ],
                              }
                            )}</ps-text-body
                          >
                        </div>`,
                    },
                  ]}
                  data={childOrgs.rows}
                  onRowSelectionChange={(event) => {
                    if (event.detail.selected && event.detail.selected.length) {
                      event.detail.selected.map((item) => {
                        const dataTable = event.currentTarget as DataTableWC;

                        dataTable['table'].resetRowSelection();

                        sendManagerEvent({
                          type: 'manager.selectChildOrg',
                          id: item.original.id,
                        });

                        navigate(`/organization/${item.original.id}/edit`);

                        return item;
                      });
                    }
                  }}
                  enableRowSelection
                ></DataTable>

                {!childOrgs ||
                  (!childOrgs.rows?.length && (
                    <Text
                      size="xsmall"
                      textAlign="center"
                      className="u-margin-top-xlarge"
                      i18nKey="manager/organizations:childOrgsList.createToGetStarted"
                    >
                      Create an org to get started.
                    </Text>
                  ))}
              </PageSection>
            </>
          )}
        </>
      </PageBody>
    </>
  );
};

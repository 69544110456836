import { Spacer, Tab, TabPanel, TabGroup } from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import React, { FC } from 'react';

import {
  ReleaseChannels,
  ReturnFormType,
  ProjectEnvironmentsType,
} from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { useManagerCtxSelector } from '../../../../xstate/app.xstate';
import { EnvironmentPanel } from './environment-panel';

import './environments.scss';

interface ProjectSettingsEnvironmentsTabProps {
  form: ReturnFormType;
  accountId?: string;
  projectId: string;
  projectEnvironments: ProjectEnvironmentsType;
  disabled?: boolean;
  environmentId?: string;
}

export const ProjectSettingsEnvironmentsTab: FC<
  ProjectSettingsEnvironmentsTabProps
> = ({
  form,
  accountId,
  projectId,
  disabled,
  environmentId,
  projectEnvironments,
}) => {
  const navigate = useNavigate();

  const { routes } = useManagerCtxSelector((ctx) => {
    return {
      routes: ctx.routes,
    };
  });

  return (
    <>
      <TabGroup placement="top-right">
        {projectEnvironments?.flatMap((channel, index) => {
          return (
            <Tab
              key={index}
              slot="tab"
              panel={channel.name}
              active={channel?.id === environmentId}
              type={
                channel?.name === ReleaseChannels.Testing
                  ? 'staging'
                  : channel?.name === ReleaseChannels.Production
                    ? 'prod'
                    : 'dev'
              }
              onClick={() => {
                navigate(
                  `${routes.projects}/${projectId}/environments/${channel?.id}`,
                  { replace: true }
                );
              }}
            >
              <TranslationComponent
                i18nKey={`manager/projects:projectDetails.environmentsTab.environmentName`}
                values={{
                  environmentName: channel?.name,
                }}
              >
                {channel?.name}
              </TranslationComponent>
            </Tab>
          );
        })}
        {projectEnvironments?.flatMap((channel, index) => {
          return (
            <TabPanel key={index} name={channel.name}>
              <EnvironmentPanel
                form={form}
                environment={channel}
                projectId={projectId}
                environmentIndex={index}
                disabled={disabled}
                accountId={accountId}
              />
            </TabPanel>
          );
        })}
      </TabGroup>
      <Spacer size="2xlarge" />
    </>
  );
};

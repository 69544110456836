import React, { useEffect, useRef } from 'react';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableWC,
  Icon,
  PageBody,
  PageSection,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components';
import { html } from 'lit/static-html.js';
import { formatDistance, parseISO } from 'date-fns';

export const Teams: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.teams.loaded');
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.teams.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { orgId, teams, defaultLanguage } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
    teams: ctx.teams,
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.teams.loadTeams');
    }
  }, [isReadyToLoad]);

  return (
    <>
      <PageBody background="none">
        <>
          <Stack alignItems="center" justifyContent="space-between">
            <TextTitle i18nKey="manager/teams:teamsList.teams">Teams</TextTitle>
            <ButtonGroup>
              <Button size="large" disabled>
                <Icon slot="prefix" name="plus" size="xsmall" />
                <TranslationComponent i18nKey="manager/teams:teamsList.createTeam">
                  Create Team
                </TranslationComponent>
              </Button>
            </ButtonGroup>
          </Stack>
          <Spacer size="2xlarge" />
          <PageSection>
            {!loaded ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader relative />
              </div>
            ) : (
              <>
                <DataTable
                  ref={tableRef}
                  columns={[
                    {
                      accessorKey: 'name',
                      header: () =>
                        t('manager/teams:teamsList.table.name', {
                          defaultValue: 'Name',
                        }) || '',
                      cell: ({ row, renderValue }) => html`
                        <div style="max-width: 400px">
                          <ps-text-subtitle size="small" truncate
                            >${row.original?.name}</ps-text-subtitle
                          >
                          ${row.original?.description
                            ? html`<ps-text-caption
                                >${row.original?.description}</ps-text-caption
                              >`
                            : ''}
                        </div>
                      `,
                    },
                    {
                      accessorKey: 'users',
                      header: () =>
                        t('manager/teams:teamsList.table.users', {
                          defaultValue: 'Users',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-avatar-group max="2">
                          ${row.original?.assignedMembers?.map(
                            (user) =>
                              html`<ps-avatar
                                label="${user.firstName}"
                                src="${user.picture || ''}"
                              ></ps-avatar>`
                          )}
                        </ps-avatar-group>`,
                    },
                    {
                      accessorKey: 'updated',
                      header: () =>
                        t('manager/teams:teamsList.table.updated', {
                          defaultValue: 'Last updated',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${formatDistance(
                            parseISO(row.original.updatedAt),
                            new Date(),
                            {
                              addSuffix: true,
                              locale:
                                dateFnsLocales[
                                  defaultLanguage || fallbackLanguage
                                ],
                            }
                          )}</ps-text-body
                        >`,
                    },
                  ]}
                  data={teams}
                  onRowSelectionChange={(event) => {
                    if (event.detail.selected && event.detail.selected.length) {
                      event.detail.selected.map((item) => {
                        const dataTable = event.currentTarget as DataTableWC;

                        dataTable['table'].resetRowSelection();

                        sendManagerEvent({
                          type: 'manager.selectTeam',
                          id: item.original.id,
                        });

                        navigate(
                          `/organization/${orgId}/teams/${item.original.id}`
                        );

                        return item;
                      });
                    }
                  }}
                  enableRowSelection
                ></DataTable>

                {!teams ||
                  (!teams.length && (
                    <Text
                      size="xsmall"
                      textAlign="center"
                      className="u-margin-top-xlarge"
                      i18nKey="manager/teams:teamsList.createToGetStarted"
                    >
                      Create a team to get started.
                    </Text>
                  ))}
              </>
            )}
          </PageSection>
        </>
      </PageBody>
    </>
  );
};

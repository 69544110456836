import React, { FC, useCallback, useEffect } from 'react';
import { IPage } from '../../types';
import {
  Button,
  PageBody,
  Stack,
  TextTitle,
  Spacer,
} from '@pypestream/design-system';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import { useForm } from 'react-hook-form';
import { OrganizationDetails } from './organization-details';

export type FormOrgInfoType = {
  name: string;
  authProvider: string;
  picture?: string;
  accountManagerId?: string;
  emailDomain?: string;
};

export const CreateOrganization: FC<IPage> = () => {
  const navigate = useNavigate();
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.users.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { routes, users, organizationId, parentOrg } = useManagerCtxSelector(
    (ctx) => ({
      users: ctx.users,
      routes: ctx.routes,
      organizationId: ctx.selectedOrgId,
      parentOrg: ctx.orgs?.find(({ id }) => id === ctx.selectedOrgId),
    })
  );

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const navigateToChildOrgs = useCallback(
    () => navigate(routes.orgs),
    [navigate, routes.orgs]
  );

  const form = useForm<FormOrgInfoType>({
    defaultValues: {
      name: '',
      authProvider: '',
      picture: '',
      accountManagerId: '',
      emailDomain: '',
    },
  });

  const onSubmit = (values: FormOrgInfoType) => {
    const authProvider =
      values.authProvider === 'same-as-parent'
        ? parentOrg?.settings?.security?.authProvider
        : values.authProvider;

    sendManagerEvent({
      type: 'manager.addOrg',
      accountName: values.name,
      picture: values.picture,
      securitySettings: {
        authProvider,
        emailDomain: values.emailDomain,
      },
      callback: (res) => {
        if (res) {
          sendManagerEvent('manager.childOrgs.loadChildOrgs');
          navigateToChildOrgs();
        }
      },
    });
  };

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.users.loadUsers');
    }
  }, [isReadyToLoad]);

  return (
    <PageBody background="none">
      <TextTitle i18nKey="manager/organizations:createOrg.title">
        Create Child Org
      </TextTitle>
      <form>
        <Spacer size="2xlarge" />
        <OrganizationDetails
          form={form}
          organizationId={organizationId || ''}
          parentId={organizationId || ''}
          users={users || []}
          isAdminRole={isAdminRole}
          isEdit={false}
        />
        {isAdminRole && (
          <Stack justifyContent="end" gutter="small">
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                form.reset();
                navigateToChildOrgs();
              }}
              i18nKey="manager/organizations:createOrg.cancel"
            >
              Cancel
            </Button>
            <Button
              size="large"
              onClick={form.handleSubmit(onSubmit)}
              disabled={!Object.keys(form.formState.dirtyFields).length}
              i18nKey="manager/organizations:createOrg.create"
            >
              Create Child Org
            </Button>
          </Stack>
        )}
      </form>
    </PageBody>
  );
};

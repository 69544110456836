import { ProductName } from '@pypestream/api-services';
import { Project } from '@pypestream/api-services/urql.limited-access.candidate';
import { compact } from 'lodash';

import { SmartContext } from '../xstate/smart.xstate-utils';
import { CommonProduct, CommonProject } from '../universal-nav/types';
import { generateBaseURLsToTools } from '../mega-menu/utils';

interface GetProductsProps {
  project: Project;
  ctx: SmartContext;
}

interface GetProducts {
  (props: GetProductsProps): CommonProduct[];
}

const getProducts: GetProducts = ({ project, ctx }) => {
  const projectId = project.id;
  const { featureFlags } = ctx;
  const agentAssistAccountId = featureFlags?.managerProjectIdForContactCenter
    ? project.accountId
    : project?.agentAssistAccountId;
  const productIds = ctx.allProducts?.reduce(
    (acc, { name, id }) => ({ ...acc, [`${name}`]: id }),
    {} as Record<ProductName, string>
  );
  const projectProducts =
    project?.projectProductSettings?.map(({ productId }) => productId) || [];
  const hasAgentAssistRoles =
    ctx.userProductRoles?.agentAssistRolesSource?.some(
      ({ project: p }) => p?.id === projectId
    );
  const hasAnalyticsRoles = ctx.userProductRoles?.analyticsRolesSource?.some(
    ({ project: p }) => p?.id === projectId
  );
  const productBaseURLs = generateBaseURLsToTools();
  const managerURL =
    ctx.onManagerToolClick && window.location.host.includes('manager')
      ? `/organization/${project.accountId}/projects/${projectId}`
      : `${productBaseURLs.Manager}/organization/${project.accountId}/projects/${projectId}`;
  const studioURL = `${productBaseURLs.Studio}/${projectId}`;
  const contactCenterUrl =
    hasAgentAssistRoles && agentAssistAccountId
      ? `${productBaseURLs.ContactCenter}/app/accounts/${agentAssistAccountId}/dashboard`
      : undefined;
  const analyticsURL = `${productBaseURLs.Analytics}/api/projects/${projectId}/change`;

  const commonProductsProps: CommonProduct[] = [
    {
      name: ProductName.Organization,
      label: 'Manager',
      logo: 'org',
      url: managerURL,
      disabled: false,
      // It means Manager is always available.
      integrated: true,
      index: 1,
    },
    {
      name: 'Studio' as ProductName,
      label: 'Studio',
      logo: 'studio',
      url: studioURL,
      conditional: true,
      // @todo: define disabled condition for Studio
      disabled: true,
      index: 4,
    },
    {
      name: ProductName.AgentAssist,
      label: 'Contact Center',
      logo: 'agentAssist',
      url: contactCenterUrl,
      conditional: true,
      disabled: !hasAgentAssistRoles,
      index: 2,
    },
    {
      name: ProductName.Analytics,
      label: 'Analytics',
      logo: 'analytics',
      // @todo: generate correct link for Analytics when service will be ready
      url: analyticsURL,
      conditional: true,
      disabled: !hasAnalyticsRoles,
      index: 3,
    },
  ];
  const products: CommonProduct[] = commonProductsProps
    .map((cProduct) => {
      const productId = cProduct.name ? productIds?.[cProduct.name] || '' : '';
      const integrated = projectId
        ? projectProducts?.includes(productId)
        : false;
      const isDisabled = cProduct.conditional
        ? cProduct.disabled || !integrated
        : cProduct.disabled;

      return {
        ...cProduct,
        id: productId,
        disabled: isDisabled && cProduct.name !== ProductName.Organization,
        integrated: cProduct.integrated || integrated,
      };
    })
    .filter((cProduct) => cProduct.integrated);

  return products;
};

type GetCommonProjectsProps = Omit<GetProductsProps, 'project'>;

interface GetCommonProjects {
  (props: GetCommonProjectsProps): CommonProject[];
}

export const getCommonProjects: GetCommonProjects = ({ ctx }) => {
  const commonProjects: CommonProject[] = compact(
    ctx.projects
      ?.map(({ projectId, ...rest }) => ({
        ...rest,
        id: projectId,
      }))
      .filter((project) => ctx.userProjects?.includes(project.id))
  );

  commonProjects.forEach((project, index) => {
    commonProjects[index].availableProducts = getProducts({
      project,
      ctx,
    });

    commonProjects[index].hasAvailableProduct =
      (commonProjects[index].availableProducts || []).filter(
        (product) => !product.disabled
      ).length > 1;
  });

  return commonProjects;
};

import { useEffect, useState, RefObject } from 'react';
import { useForm } from 'react-hook-form';
import { StepperWC } from '@pypestream/design-system';
import {
  sendManagerEvent,
  useManagerStateMatches,
} from '../../xstate/app.xstate';
import {
  FormProjectInfoType,
  FormProjectLocalizationType,
  FormProjectReleaseChannelsType,
  FormProjectStateType,
  FormProjectToolingType,
  OutputProjectFormType,
  ReleaseChannels,
} from '../../components';
import { DomainTypeEnum } from '@pypestream/api-services';

const formProjectInfoDefaultValues = {
  name: '',
  description: '',
  projectIconId: '',
  projectIcon: '',
};

const formProjectLocalizationDefaultValues = {
  countryId: '',
  timeZoneId: '',
  localeId: '',
};

const formProjectToolingDefaultValues = {
  productIds: [],
};

const formProjectReleaseChannelsDefaultValues = {
  projectReleaseChannelConfigs: [
    {
      name: ReleaseChannels.Production,
      domains: [{ type: DomainTypeEnum.Domain, url: '' }],
    },
    {
      name: ReleaseChannels.Testing,
      domains: [],
    },
    {
      name: ReleaseChannels.Development,
      domains: [],
    },
  ],
};

const outputProjectReleaseChannelsDefaultValues = {
  projectReleaseChannelConfigs: [],
  applets: [],
};

const stateDefaultValues: FormProjectStateType = {
  ...formProjectInfoDefaultValues,
  ...formProjectLocalizationDefaultValues,
  ...formProjectToolingDefaultValues,
  ...outputProjectReleaseChannelsDefaultValues,
};

export const useProjectWizardForm = (swiperRef: RefObject<StepperWC>) => {
  const [projectFormState, setProjectFormState] =
    useState<OutputProjectFormType>(stateDefaultValues);

  const isProjectAdded = useManagerStateMatches(
    'orgRelated.ready.projects.transformAfterDelay'
  );

  const projectInfoForm = useForm<FormProjectInfoType>({
    defaultValues: formProjectInfoDefaultValues,
  });

  const projectReleaseChannelsForm = useForm<FormProjectReleaseChannelsType>({
    defaultValues: formProjectReleaseChannelsDefaultValues,
    // https://react-hook-form.com/docs/useform#mode
    // This option allows you to configure the validation strategy before a user submits the form.
    // all: Validation is triggered on both blur and change events.
    mode: 'all',
  });

  const projectToolingForm = useForm<FormProjectToolingType>({
    defaultValues: formProjectToolingDefaultValues,
  });

  const projectLocalizationForm = useForm<FormProjectLocalizationType>({
    defaultValues: formProjectLocalizationDefaultValues,
  });

  const updateFormState = (formData?: Partial<OutputProjectFormType>) => {
    if (!formData) {
      return;
    }

    setProjectFormState((prevState) => ({
      ...prevState,
      ...formData,
    }));
  };

  const createProject = (formData?: Partial<OutputProjectFormType>) => {
    const projectState = {
      ...projectFormState,
      ...formData,
    };
    sendManagerEvent({
      type: 'manager.addProject',
      name: projectState.name,
      description: projectState.description,
      productIds: projectState.productIds,
      countryId: projectState.countryId,
      timeZoneId: projectState.timeZoneId,
      localeId: projectState.localeId,
      projectReleaseChannelConfigs: projectState.projectReleaseChannelConfigs,
      projectIconId: projectState.projectIconId,
    });
  };

  const resetFormState = () => {
    projectInfoForm.reset();
    projectReleaseChannelsForm.reset();
    projectToolingForm.reset();
    projectLocalizationForm.reset();
    setProjectFormState(stateDefaultValues);
    swiperRef?.current?.goToStep(0);
  };

  const handleNextStep = (formData?: Partial<OutputProjectFormType>) => {
    updateFormState(formData);
    swiperRef?.current?.nextStep();
  };

  const handlePrevStep = () => swiperRef?.current?.backStep();

  useEffect(() => {
    if (isProjectAdded) {
      swiperRef?.current?.nextStep();
    }
  }, [isProjectAdded, swiperRef]);

  return {
    updateFormState,
    resetFormState,
    createProject,
    projectInfoForm,
    projectReleaseChannelsForm,
    projectToolingForm,
    projectLocalizationForm,
    handleNextStep,
    handlePrevStep,
  };
};

import React, { FC, useCallback, useEffect, useRef, useMemo } from 'react';
import { IPage } from '../../types';
import {
  Button,
  Modal,
  ModalIcon,
  ModalProps,
  PageBody,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';

import { OrganizationDetails } from './organization-details';
import { useForm } from 'react-hook-form';
import { FormOrgInfoType } from './create-organization';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import { useNavigate } from 'react-router-dom';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';
import { TranslationComponent } from '@pypestream/translations';
import { Loader } from '../../../components';

export const OrganizationSettings: FC<IPage> = () => {
  const navigate = useNavigate();
  const { routes, users, organizationId, currentOrg, orgs } =
    useManagerCtxSelector((ctx) => ({
      users: ctx.users,
      routes: ctx.routes,
      orgs: ctx.orgs,
      organizationId: ctx.selectedOrgId,
      currentOrg: ctx.orgs?.find(({ id }) => id === ctx.selectedOrgId),
    }));

  const parentOrg = useMemo(() => {
    if (currentOrg && currentOrg.parentId) {
      return orgs?.find(({ id }) => id === currentOrg.parentId);
    }

    return null;
  }, [currentOrg, orgs]);

  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.users.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const form = useForm<FormOrgInfoType>({
    defaultValues: {
      name: '',
      authProvider: '',
      picture: '',
      accountManagerId: '',
      emailDomain: '',
    },
  });

  const modalRef = useRef<ModalProps>(null);

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const navigateToChildOrgs = useCallback(
    () => navigate(routes.orgs),
    [navigate, routes.orgs]
  );

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.users.loadUsers');
    }
  }, [isReadyToLoad]);

  useEffect(() => {
    if (!currentOrg) return;

    const orgDetails = {
      name: currentOrg.name,
      authProvider: currentOrg.settings?.security?.authProvider,
      picture: currentOrg.picture,
      accountManagerId: currentOrg.accountManagerId,
      emailDomain: currentOrg.settings?.security?.emailDomain || '',
    };
    form.reset({ ...orgDetails });
  }, [form, currentOrg]);

  const onSubmit = (values: FormOrgInfoType) => {
    const authProvider =
      values.authProvider === 'same-as-parent'
        ? parentOrg?.settings?.security?.authProvider
        : values.authProvider;

    sendManagerEvent({
      type: 'manager.updateOrg',
      name: values.name,
      picture: values.picture,
      accountManagerId: currentOrg?.accountManagerId,
      generalSettings: currentOrg?.settings?.general ?? {},
      resourceLimitSettings: currentOrg?.settings?.resourceLimits ?? {},
      securitySettings: {
        ...(currentOrg?.settings?.security || {}),
        authProvider,
        emailDomain: values.emailDomain,
      },
    });
  };

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.open = false;
    }
  };

  const openModal = () => {
    if (modalRef.current) {
      modalRef.current.open = true;
    }
  };

  const deleteOrg = () => {
    if (currentOrg) {
      sendManagerEvent({
        type: 'manager.deleteOrg',
        id: currentOrg.id,
        callback: (res) => {
          if (res) {
            navigate(`/organization/${currentOrg.parentId}/child-orgs`);
          }
        },
      });
      closeModal();
    }
  };

  if (!currentOrg) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader relative />
      </div>
    );
  }

  return (
    <PageBody background="none">
      <TextTitle>{currentOrg.name}</TextTitle>
      <form>
        <Spacer size="2xlarge" />
        <OrganizationDetails
          form={form}
          organizationId={organizationId || ''}
          parentId={currentOrg.parentId || ''}
          users={users || []}
          isAdminRole={isAdminRole}
          isEdit
        />
        {isAdminRole && (
          <Stack justifyContent="space-between" alignItems="center">
            <Stack>
              {parentOrg && (
                <Button
                  variant="warning"
                  size="large"
                  onClick={openModal}
                  i18nKey="manager/organizations:editOrg.delete"
                >
                  Delete Org
                </Button>
              )}
            </Stack>
            <Stack>
              <Button
                variant="secondary"
                size="large"
                onClick={() => {
                  form.reset();
                  navigateToChildOrgs();
                }}
                i18nKey="manager/organizations:editOrg.cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                onClick={form.handleSubmit(onSubmit)}
                disabled={!Object.keys(form.formState.dirtyFields).length}
                i18nKey="manager/organizations:editOrg.save"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        )}
      </form>
      <Modal ref={modalRef} size="medium" onClose={closeModal}>
        <ModalIcon
          name="error"
          slot="header"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <Stack slot="header" alignItems="center" direction="column">
          <TextTitle
            size="small"
            i18nKey="manager/organizations:editOrg.deleteModal.areYouSure"
          >
            Are you sure?
          </TextTitle>
          <TextBody variant="secondary">
            <TranslationComponent
              i18nKey="manager/organizations:editOrg.deleteModal.description"
              values={{
                usersCount: currentOrg?.users?.count,
                projectsCount: currentOrg?.projects?.count,
              }}
            ></TranslationComponent>
          </TextBody>
        </Stack>
        <Stack slot="footer" justifyContent="end">
          <Button
            variant="ghost"
            size="large"
            type="button"
            onClick={closeModal}
            i18nKey="manager/organizations:editOrg.deleteModal.cancel"
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="warning"
            data-cy="delete-org"
            type="button"
            i18nKey="manager/organizations:editOrg.deleteModal.removeOrg"
            onClick={deleteOrg}
          >
            Delete Org
          </Button>
        </Stack>
      </Modal>
    </PageBody>
  );
};

import { FC } from 'react';
import {
  Button,
  PageBody,
  PageSection,
  Spacer,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';

import { IPage } from '../types';
import { sendManagerEvent } from '../../xstate/app.xstate';

export const SuperAdminPage: FC<IPage> = ({ title }) => {
  const makeSnapshot = () => {
    sendManagerEvent({
      type: 'manager.createDbSnapshot',
    });
  };

  return (
    <PageBody background="none">
      <TextTitle i18nKey="manager/super-admin:title">{title}</TextTitle>
      <Spacer size="2xlarge" />

      <PageSection>
        <TextSubtitle i18nKey="manager/super-admin:section1.title" size="small">
          This button is for making a snapshot of DB
        </TextSubtitle>
        <Spacer size="large" />
        <Button
          i18nKey="manager/super-admin:section1.button"
          onClick={() => makeSnapshot()}
        >
          Make Snapshot
        </Button>
      </PageSection>
      <PageSection>
        <TextSubtitle i18nKey="manager/super-admin:section2.title" size="small">
          Link to Analytics Settings Page
        </TextSubtitle>
        <Spacer size="large" />
        <Button
          i18nKey="manager/super-admin:section2.button"
          href="https://analytics.main.pypestream.dev/instance/settings"
        >
          Analytics
        </Button>
      </PageSection>
      <PageSection>
        <TextSubtitle i18nKey="manager/super-admin:section3.title" size="small">
          Link to Contact Center Super Admin Page
        </TextSubtitle>
        <Spacer size="large" />
        <Button
          i18nKey="manager/super-admin:section3.button"
          href="https://contactcenter.pypestream.com/super_admin"
        >
          Contact Center
        </Button>
      </PageSection>
    </PageBody>
  );
};

/* eslint-disable react/prop-types */

import { FC, ForwardedRef, forwardRef, useRef, useState } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';
import {
  AppletInstance,
  DomainTypeEnum,
  ProductName,
  urqlGql,
} from '@pypestream/api-services';
import { CreateProjectMutationVariables } from '@pypestream/api-services/urql';
import {
  AutoSave,
  AutoSaveState,
  Button,
  EntityTypes,
  IconButton,
  Image,
  ImageProps,
  Input,
  InputProps,
  Modal,
  ModalIcon,
  Select,
  SelectOption,
  SelectProps,
  Stack,
  TextBody,
  TextTitle,
  Textarea,
  TextareaProps,
  Upload,
  UploadProps,
} from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';

import { Product } from '../../utils';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../xstate/app.xstate';
import { ProductCTA } from '../product-cta';
import { UPLOAD_SQUARE_ICON_BASE64 } from '..';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnFormType = UseFormReturn<any, unknown, undefined>;

type InputControllerPropsType = {
  form: ReturnFormType;
  name: string;
  index: number;
  placeholder?: string;
  isForbiddenForRemove?: boolean;
  disabled?: boolean;
  variant?: InputProps['variant'];
  validate?: boolean;
  required?: boolean;
  remove: (index: number) => void;
};

type ProjectDetailsFieldName =
  | 'name'
  | 'description'
  | 'countryId'
  | 'localeId'
  | 'timeZoneId'
  | 'projectIcon';
// | 'productIds';

export type FormProjectInfoType = {
  name: string;
  description?: string;
  projectIconId?: string;
  projectIcon?: string;
};

export type FormProjectLocalizationType = {
  countryId?: string;
  timeZoneId?: string;
  localeId?: string;
};

export type FormProjectToolingType = {
  productIds: string[];
};

export type ProjectEnvironmentType = {
  id: string;
  name: string;
  description?: string;
  domains?: {
    type: DomainTypeEnum;
    url: string;
  }[];
};

export type ProjectEnvironmentsType = ProjectEnvironmentType[];

export type FormProjectReleaseChannelsType = {
  applets: Partial<AppletInstance>[];
  projectReleaseChannelConfigs: ProjectEnvironmentsType;
};

export type FormProjectStateType = FormProjectInfoType &
  FormProjectLocalizationType &
  FormProjectToolingType &
  FormProjectReleaseChannelsType;

export type OutputProjectFormType = Omit<
  CreateProjectMutationVariables,
  'accountId'
>;

export const ReleaseChannels = {
  Production: 'Production',
  Testing: 'Testing',
  Development: 'Development',
};

export type ReleaseChannelsType = keyof typeof ReleaseChannels;

const useAutosave = (
  form: ReturnFormType,
  fieldName: ProjectDetailsFieldName
) => {
  const success = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.success`
  )
    ? AutoSaveState.success
    : undefined;

  const error = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.error`
  )
    ? AutoSaveState.error
    : undefined;

  const loading = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.loading`
  )
    ? AutoSaveState.loading
    : undefined;

  const projectId = useManagerCtxSelector((ctx) => ctx.selectedProject);

  const autosaveFieldState: AutoSaveState | undefined =
    loading || success || error;

  const onFileldSaved = ({ detail: { value: data } }: CustomEvent) => {
    sendManagerEvent({
      type: `manager.updateProject.${fieldName}`,
      projectId,
      data,
    });
  };

  const onFieldChanged = ({ detail: { value: updatedValue } }: CustomEvent) => {
    form.setValue(fieldName, updatedValue.value);
  };

  return {
    autosaveFieldState,
    onFileldSaved,
    onFieldChanged,
  };
};

export class ProjectFormFields {
  public ProjectNameField = forwardRef(
    (
      props: InputProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<InputProps>
    ) => {
      const { field: nameField, fieldState: nameFiledState } = useController({
        name: 'name',
        control: props._form.control || undefined,
        rules: { required: 'This is a required field.' },
      });

      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'name'
      );

      const field = (
        <Input
          ref={ref}
          placeholder="Project name"
          type="text"
          autocomplete="off"
          value={nameField.value}
          helpText={nameFiledState.error?.message}
          hasError={nameFiledState.invalid}
          name={nameField.name}
          onChange={nameField.onChange}
          {...props}
        />
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectDescriptionField = forwardRef(
    (
      props: TextareaProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<TextareaProps>
    ) => {
      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'description'
      );

      const field = (
        <Textarea
          ref={ref}
          placeholder="Description (optional)"
          autocomplete="off"
          {...props}
        />
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectIconField: FC<{
    uploadProps?: UploadProps;
    imageProps?: ImageProps;
    form: ReturnFormType;
    readOnly?: boolean;
  }> = ({ uploadProps, imageProps, form, readOnly }) => {
    const { field: projectIconField } = useController({
      name: 'projectIcon',
      control: form.control || undefined,
    });

    const { field: projectIconIdField } = useController({
      name: 'projectIconId',
      control: form.control || undefined,
    });
    const [t] = useTranslation();

    const projectId = useManagerCtxSelector((ctx) => ctx.selectedProject);

    const field = (
      <Upload
        readonly={readOnly}
        entityType={EntityTypes.PROJECT}
        text={
          t(
            'manager/projects:projectDetails.generalTab.details.placeholders.dragPhoto',
            { defaultValue: 'Drag a photo here' }
          ) || ''
        }
        cta={
          t(
            'manager/projects:projectDetails.generalTab.details.placeholders.selectFile',
            { defaultValue: 'Select a file' }
          ) || ''
        }
        value={projectIconField.value}
        name={projectIconField.name}
        onChange={(e) => {
          sendManagerEvent({
            type: 'manager.updateProject.projectIcon',
            projectId,
            data: {
              name: 'projectIcon',
              value: e?.target?.value as string,
            },
          });
          projectIconField.onChange(e);
          projectIconIdField.onChange({
            ...e,
            target: {
              ...e.target,
              value: e.target.valueId,
            },
          });
        }}
        {...uploadProps}
      >
        <Image
          width="56px"
          height="56px"
          slot="preview"
          src={projectIconField.value}
          alt={`${form.watch('name') || 'Projectname'}`}
          fallback={UPLOAD_SQUARE_ICON_BASE64}
          {...imageProps}
        />
      </Upload>
    );
    return field;
  };

  public CountrySelect = forwardRef(
    (
      props: SelectProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<SelectProps>
    ) => {
      const countriesList = useManagerCtxSelector(({ countries }) => countries);
      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'countryId'
      );

      const field = (
        <Select placeholder="Country" ref={ref} {...props}>
          {countriesList.map((country) => (
            <SelectOption key={country.id} value={country.id}>
              {country.name}
            </SelectOption>
          ))}
        </Select>
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public TimeZoneSelect = forwardRef(
    (
      props: SelectProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<SelectProps>
    ) => {
      const timeZones = useManagerCtxSelector(({ timezones }) => timezones);
      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'timeZoneId'
      );

      const field = (
        <Select
          searchKeys={['identifier', 'label']}
          data={timeZones}
          placeholder="Time zone"
          ref={ref}
          {...props}
        >
          {timeZones.map((timeZone) => {
            return (
              <SelectOption key={timeZone.id} value={timeZone.id}>
                {timeZone.label}
              </SelectOption>
            );
          })}
        </Select>
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public LanguageSelect = forwardRef(
    (
      props: SelectProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<SelectProps>
    ) => {
      const languagesList = useManagerCtxSelector(
        ({ languages, localizationSettingsConfig }) =>
          languages.filter(
            ({ languageCode }) =>
              !languageCode ||
              (
                localizationSettingsConfig?.project.supportedLanguageCodes || []
              ).includes(languageCode)
          )
      );
      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'localeId'
      );

      const field = (
        <Select placeholder="Language: English (default)" ref={ref} {...props}>
          {languagesList.map((language) => (
            <SelectOption key={language.id} value={language.id}>
              {language.name}
            </SelectOption>
          ))}
        </Select>
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectEnvironmentField: FC<InputControllerPropsType> = ({
    form,
    name,
    index,
    placeholder,
    isForbiddenForRemove,
    variant,
    validate = true,
    required = false,
    disabled,
    remove,
  }) => {
    const rules = validate
      ? {
          required: 'This is a required field.',
          validate: async (value: string) => {
            const result = await urqlGql.validateProjectReleaseChannelUrls({
              urls: [value],
            });

            let errorMessage = 'This field should be a valid url.';

            const intersection =
              result?.data?.admin_?.validateProjectReleaseChannelUrls?.urls
                ?.map(
                  ({ intersections }) =>
                    intersections?.map((url) => url).join(', ') || ''
                )
                .join(', ') || '';

            if (intersection) {
              errorMessage = `This URL (${intersection}) is used in another project`;
            }

            const validationError =
              result?.data?.admin_?.validateProjectReleaseChannelUrls?.urls
                ?.map(({ validationResult }) => validationResult)
                .join(', ');

            if (validationError) {
              errorMessage = validationError;
            }

            return (
              Boolean(
                result?.data?.admin_?.validateProjectReleaseChannelUrls?.urls?.every(
                  ({ isValid }) => isValid
                )
              ) || errorMessage
            );
          },
        }
      : {};

    const { field, fieldState } = useController({
      name: `${name}.${index}.url`,
      control: form.control,
      rules,
    });

    return (
      <>
        <Input
          variant={variant}
          placeholder={placeholder}
          required={required}
          type="text"
          value={field.value}
          helpText={fieldState.error?.message}
          hasError={fieldState.invalid}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={disabled}
        />
        {!isForbiddenForRemove && (
          <IconButton name="delete" onClick={() => remove(index)} />
        )}
      </>
    );
  };

  public ProjectToolField: FC<{
    logo: Product['logo'];
    label: string;
    productId: string;
    confirmRemove?: boolean;
    form: ReturnFormType;
    productName?: ProductName;
    required?: boolean;
    disabled?: boolean;
  }> = ({
    form,
    logo,
    label,
    productId,
    productName,
    required,
    disabled = false,
    confirmRemove = false,
  }) => {
    const { field } = useController({
      name: 'productIds',
      control: form.control,
      rules: { validate: (value) => !!value?.length },
    });
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const onInputChange = (checked: boolean) => {
      if (checked) {
        if (field.value?.includes(productId)) return;

        field.onChange([...field.value, productId]);
      } else {
        if (!field.value?.includes(productId)) return;

        field.onChange(field.value?.filter((id: string) => id !== productId));
      }
    };

    const closeModal = () => setIsModalOpened(false);

    const onClick = () => setIsModalOpened(true);

    const fieldIsChecked = !!field.value?.includes(productId);

    const eventHandler = confirmRemove
      ? fieldIsChecked
        ? { onClick }
        : { onInputChange }
      : { onInputChange };

    return (
      <>
        <ProductCTA
          ref={inputRef}
          logo={logo}
          inputName={field.name}
          inputValue={productId}
          required={required}
          disabled={disabled}
          name={label}
          checked={fieldIsChecked}
          productName={productName}
          {...eventHandler}
        />
        {confirmRemove && (
          <Modal
            open={isModalOpened}
            size="medium"
            stayOnClickOutside
            stayOnEsc
            onClose={closeModal}
          >
            <ModalIcon
              name="error"
              slot="header"
              style={{ display: 'flex', justifyContent: 'center' }}
            />
            <Stack slot="header" alignItems="center" direction="column">
              <TextTitle size="small">Are you sure?</TextTitle>
              <TextBody variant="secondary">
                You&lsquo;ll permanently lose your:
                <ul>
                  <li>access to this tool</li>
                  <li>assigned roles</li>
                  <li>data inside the tool</li>
                </ul>
              </TextBody>
            </Stack>
            <Stack slot="footer" justifyContent="end">
              <Button variant="ghost" size="large" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                size="large"
                variant="warning"
                onClick={() => {
                  onInputChange(false);
                  closeModal();
                }}
              >
                Remove Tool
              </Button>
            </Stack>
          </Modal>
        )}
      </>
    );
  };
}
